import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/index"

const NuQKitchen = ({ location }) => {
  return (
    <>
      <Layout location={location}>
        <Seo title="NuQ" />
        <Hero />
      </Layout>
    </>
  )
}

export default NuQKitchen
