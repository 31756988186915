import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => (
  <div id="hero">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="hero-circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="148.679"
              height="161.784"
              viewBox="0 0 148.679 161.784"
            >
              <g
                id="Group_72869"
                data-name="Group 72869"
                transform="translate(-196.991 -290.005)"
              >
                <g id="Group_73507" data-name="Group 73507">
                  <path
                    id="Path_37282"
                    data-name="Path 37282"
                    d="M74.768,110.623c-15.446,0-29.252-6.793-39.922-19.649C18.333,71.091,10.551,37.87,15.054,6.341a101.62,101.62,0,0,1,4.684-19.558c8.471-24.112,28.927-37.944,56.1-37.944a82.6,82.6,0,0,1,9.915.612,97.574,97.574,0,0,1,52.74,24.385A79.909,79.909,0,0,1,152.543-9.43C172.5,22.646,161.99,59.718,124.41,89.764c-14.873,11.906-29.356,18.777-43.058,20.456A54.328,54.328,0,0,1,74.768,110.623Z"
                    transform="translate(183.198 341.166)"
                    fill="#01e1ca"
                  />
                  <text
                    id="SALMONWITH"
                    transform="translate(211 377.522)"
                    fill="#fff"
                    font-size="38"
                    font-family="Rubik-Medium, Rubik"
                    font-weight="500"
                  >
                    <tspan x="0" y="0">
                      NuQ™
                    </tspan>
                  </text>
                </g>
              </g>
            </svg>
          </div>
          <div className="hero-left-content">
            <h3 className="hero-subtitle">Introducing a New Health Paradigm</h3>
            <h1 className="hero-title text-d-cyan">
              Nutrient&nbsp;
              <br />
              Quotient/&nbsp;
              <br />
              NuQ™
            </h1>
            <h3 className="hero-subtitle">Count Nutrients, NOT calories!</h3>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="hero-right-content text-left">
            <StaticImage
              placeholder="dominantColor"
              className="img-fluid"
              src="../../images/home2/hero-avocado.png"
              alt="Hero Avocado"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="avocado-bg">
      <StaticImage
        placeholder="dominantColor"
        className="img-fluid"
        src="../../images/home2/hero-avocado-2.png"
        alt="Hero Avocado 2"
      />
    </div>
  </div>
)

export default Hero
